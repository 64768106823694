




































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { RmReportForms } from '@/services/rm-report-forms-service';
import bonuseUpload from './compoennts/bonuseUpload.vue';
import { getMoney } from '@/services/utils';
@Component({
  components: {
    bonuseUpload
  }
})
export default class wechatImageText extends Vue {
    public form: any = {
        type:1,
        pages: 1,
        size: 10,
        current: 1,
        total: 0
    };
    private tableData: any[] = [];
    public position: any[] = [];
    public franchise: any[] = [];
    public applications: any[] = [];
    public CreateTime: any[] = [];
    public kingList: any[] = [];
    public loading: Boolean = true;
    public kindshow: Boolean = false;
    private currentRow: any = null;
    private linkPre: string = '';
    @Inject(RmReportForms) private rmService!: RmReportForms;

    public async created(): Promise<void> {
        this.franchiseList();
        await this.getList();
    }
    //franchise
    public async franchiseList(): Promise<void> {
        const res = await this.rmService.franchise();
        this.franchise = res
    }
    public search() {
        this.form.current = 1;
      if(this.form.franchise){
        this.form.franchiseParam = this.form.franchise.replace(new RegExp("\\&","g"),"%26")
      }
        this.getList()
    }
  public clearEvent(){
    this.form.franchiseParam = ''
  }
    public actives(type:any){
        this.form.type = type;
        this.search()
    }
    //分页相关
    public handleSizeChange(size: number) {
        this.form.size = size;
        this.getList();
    }
    public handleCurrentChange(current: number) {
        this.form.current = current;
        this.getList();
    }
     //获取数据源
     public async getList() {
        this.loading = true;
        const res = await this.rmService.reportFromList(this.form);
        this.loading = false;
       let data: any = res.records.map((element: any) => {
         element.reachBonus100 =  getMoney(element.reachBonus100);
         element.growBonus100 =  getMoney(element.growBonus100);
         element.growthAward =  getMoney(element.growthAward);
         element.achievementAward =  getMoney(element.achievementAward);
         element.estimatedSalesVolume =  getMoney(element.estimatedSalesVolume);
         element.estimatedAmount =  getMoney(element.estimatedAmount);
         element.targetMoneySalesVolume =  getMoney(element.targetMoneySalesVolume);
         element.targetMoneySalesMoney =  getMoney(element.targetMoneySalesMoney);
         element.lastSaleVolume =  getMoney(element.lastSaleVolume);
         element.lastSaleMoney =  getMoney(element.lastSaleMoney);
         return element;
       });
        this.tableData = data
        this.form.total = res.total;
        this.form.size = res.size;
        this.form.current = res.current;
    }


    //导出
    public async exportReport() {
        if(this.form.franchise == undefined || this.form.franchise == ''){
            this.$message({
                message: '请选择Franchise',
                type: 'warning'
            });
            return;
        }
        this.loading = true;
        const res = await this.rmService.exportReport(this.form,'RM奖金预估报表.xlsx');
        this.loading = false;
    }
    // 重置
    public reset() {
        this.form = {
            pages: 1,
            size: 10,
            current: 1,
            total: 0
        };
        this.CreateTime = [];
        this.search()
    }
    private mounted() {
        document.onkeydown = (e) => {
            let key = window.event.keyCode;
            if (key == 13) {
                this.search()
            }
        }
    }

}
